import { v4 as uuidv4 } from 'uuid';

export type WebsiteVisitorCallback = (
  payload: WebsiteVisitorRequest
) => Promise<void>;

export type WebsiteVisitorRequest = WebsiteVisitorRequest_V1;

export type WebsiteVisitorRequest_V1 = {
  version: "v1";
  eventName: EventName;
  eventTimeEpochMs: number;
  deploymentId: string;
  currentPageUrl: string;
  referrer: string;
  userAgent: string;
};

export type EventName = "pageLoad";

export const pocusSessionId = uuidv4();

export const createWebSiteVisitorRequest = (
  projectId: string
): WebsiteVisitorCallback => {
  return async (payload: WebsiteVisitorRequest) => {
    await fetch(`${POCUS_API_EVENT_INGESTION_URL}/v1/visitor-events/enqueue`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: projectId,
      },
      // TODO - batch the events internally and flush
      body: JSON.stringify({
        events: [payload],
        pocusSessionId,
      }),
    });
  };
};
