import { createWebSiteVisitorRequest } from "./requests";

async function main(): Promise<void> {
  const scriptElement =
    document.currentScript ||
    document.querySelector("script[data-pocus-project-id]");

  // change to dataset?
  const apiToken = scriptElement?.getAttribute("data-pocus-project-id");
  if (!apiToken) {
    console.error("Pocus SDK: Missing Pocus project key!");
    return;
  }

  const apiCall = createWebSiteVisitorRequest(apiToken);
  apiCall({
    eventName: "pageLoad",
    eventTimeEpochMs: Date.now(),
    deploymentId: DEPLOYMENT_ID,
    currentPageUrl: window.location.href,
    referrer: document.referrer,
    userAgent: navigator.userAgent,
    version: "v1",
  });
}
main();
